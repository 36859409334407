import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import AppStageView from 'components/common/AppStageView';
import Flex from 'components/common/Flex';
import OfflineNetwork from 'components/errors/OfflineNetwork';
import CryptoJS from 'crypto-js';
import { AuthProvider, useAuth } from 'hooks/useAuth';
import _ from 'lodash';
import moment from 'moment/moment';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, BrowserRouter as Router } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import semver from 'semver';
import {
  actionSetAppLoadError,
  actionSetNetworkStatusOffline,
  actionSetNetworkStatusOnline,
  addDevice,
  initStartupQS,
  refreshAllAccountInfo,
  refreshDataExportMonths,
  setStageValue
} from 'stores/app';
import { setPreAuthEmail, setToken } from 'stores/authentication';
import { showAlert, showConfirm } from 'stores/modal';
import { actionAddNotification } from 'stores/notification';
import api from 'util/api';
import apiHelper from 'util/apiHelper';
import c from 'util/const';
import juratLocalStorage from 'util/local-storage';
import logger from 'util/logger';
import pkg from '../package.json';
import Layout from './layouts/Layout';
import store from 'app/store';

const App = () => {
  const [isLoadingProfile, setLoadingProfile] = useState(false);
  const [isProfileLoaded, setProfileLoaded] = useState(false);
  const [showingVersionPrompt, setShowingVersionPrompt] = useState(false);
  const token = juratLocalStorage.get(c.storage.token);
  const { networkStatus, userMaster } = useSelector(state => state.app);
  const auth = useAuth();
  const [showingDialog, setShowingDialog] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionSetAppLoadError(null));
    dispatch(actionSetNetworkStatusOnline());
    dispatch(setStageValue(c.stages.NULL));
    if (token) {
      showVersionDialogIfNeeded();
      logger.log('Token active');
      if (!isLoadingProfile && !isProfileLoaded) {
        setLoadingProfile(true);
        dispatch(setStageValue(c.stages.TOKEN));
        api.Token.test(token)
          .then(res => {
            logger.log('Response App token', res);
            if (
              apiHelper.isTokenExpired(res) &&
              apiHelper.isAuthorizationError(res)
            ) {
              dispatch(setStageValue(c.stages.LOGOUT));
            }
            setProfileLoaded(true);
            api.setToken(token);
            dispatch(setToken(token));
            return dispatch(refreshAllAccountInfo())
              .then(res => {
                dispatch(refreshDataExportMonths());
                dispatch(setStageValue(c.stages.NULL));
                let userMaster = store.getState().app.userMaster;
                let subscriptionStatus =
                  store.getState().subscription.subscriptionStatus;
                let activeSubscriptions = _.get(
                  subscriptionStatus,
                  'Active',
                  []
                );
                if (activeSubscriptions?.length == 0) {
                  dispatch(
                    actionAddNotification({
                      message: 'Subscription expired or cancelled. ',
                      name: userMaster.FirstName + ' ' + userMaster.LastName
                    })
                  );
                }
                setLoadingProfile(false);
                logger.log('Login scuccessfull calling handle', res);

                dispatch(addDevice()).then(res => {
                  logger.log('Login scuccessfull calling handle data', res);
                  if (_.isEqual(res.toString(), c.device.status_blocked)) {
                    if (!showingDialog) {
                      setShowingDialog(true);
                      dispatch(
                        showAlert(
                          'Device Blocked',
                          'Your Device is blocked, You will no longer allowed to use this device for notarization.'
                        )
                      );
                    }
                  } else {
                    logger.log(
                      'Login scuccessfull calling handle else',
                      res,
                      c.device.status_blocked
                    );
                    handleStartup();
                  }
                });

                //handleStartup();
              })
              .catch(err => {
                //juratLocalStorage.set(c.storage.token, null);
                logger.log('refresh All failed', err);
                setLoadingProfile(false);
                handleStartup();
              });
          })
          .catch(err => {
            juratLocalStorage.set(c.storage.token, null);
            if (err === 'NETWORK_OFFLINE') {
              dispatch(actionSetNetworkStatusOffline());
              toast.error(`Session expired. Login to continue.`, {
                theme: 'colored',
                position: 'bottom-center',
                icon: faExclamationTriangle,
                toastId: 'auth-token-expired'
              });
              dispatch(setStageValue(c.stages.LOGOUT));
            } else if (apiHelper.isTokenExpired(err)) {
              toast.error(`Session expired. Login to continue.`, {
                theme: 'colored',
                position: 'bottom-center',
                icon: faExclamationTriangle,
                toastId: 'auth-token-expired'
              });
              dispatch(setStageValue(c.stages.LOGOUT));
            } else if (apiHelper.isAuthorizationError(err)) {
              toast.error(`Authorization error`, {
                theme: 'colored',
                position: 'bottom-center',
                icon: faExclamationTriangle,
                toastId: 'auth-token-expired'
              });
              dispatch(setStageValue(c.stages.LOGOUT));
            }
            setLoadingProfile(false);
            logger.log('Error on Token Text Catch', err);
          });
        <Navigate to="/login" replace={true} />;
      }
    } else {
      // logger.log('Token expired');
      // toast.error(`Authorization token expired.`, {
      //   theme: 'colored',
      //   position: 'bottom-center',
      //   icon: faExclamationTriangle,
      //   toastId: 'auth-token-expired'
      // });
      handleStartup();
    }
  }, []);

  const getParsedQuerystring = () => {
    //This is pretty dumb.  Apparently the response from querystring.parse is not a serializable object
    //even though it is.  You get react errors if you pass this parsed result to initStartupQS.  booooooo.
    return _.extend({}, queryString.parse(location.search));
  };

  const handleStartup = () => {
    logger.log('APP STARTUP', location);

    let parsed = getParsedQuerystring();
    if (!parsed || _.isEmpty(parsed)) {
      logger.log('Parsed redirecting to Dashboard');
      //navigate('/');
      <Navigate to="/" />;
      return;
    } else {
      logger.log('handle startup querystring', parsed);
      dispatch(initStartupQS(parsed));

      if (
        parsed[c.querystring.flow] &&
        parsed[c.querystring.flow] === 'signup'
      ) {
        //kill their token if they're coming in from this deeplink.
        //I'm worried about conflicts between auto-login stuff and this.
        //This is an explicit signup link, so sign them up.
        //auth.logout();
        juratLocalStorage.remove(c.storage.token);

        juratLocalStorage.set(
          c.storage.email,
          parsed[c.querystring.email] || ''
        );
        dispatch(setPreAuthEmail(parsed[c.querystring.email] || ''));
        <Navigate to="/signup" />;
        return;
      } else if (
        parsed[c.querystring.campaignId] &&
        parsed[c.querystring.leadSource]
      ) {
        //kill their token if they're coming in from this deeplink.
        //I'm worried about conflicts between auto-login stuff and this.
        //This is an explicit signup link, so sign them up.
        auth.unsetAuth();
        juratLocalStorage.remove(c.storage.token);
        <Navigate to="/signup" />;
        return;
      } else if (
        parsed[c.querystring.code] &&
        parsed[c.querystring.verifyType]
      ) {
        handleAuthenticatedQuerystring(parsed);
        return;
      } else if (
        parsed[c.querystring.flow] &&
        parsed[c.querystring.flow] === 'subscription'
      ) {
        handleAuthenticatedQuerystring(parsed);
      } else if (
        parsed[c.querystring.signingGUID] &&
        parsed[c.querystring.email]
      ) {
        handleAuthenticatedQuerystring(parsed);
      } else {
        <Navigate to="/" />;
        return;
      }
    }
  };

  const showVersionDialogIfNeeded = () => {
    if (showingVersionPrompt) {
      logger.log('already showing prompt.  skipping');
      return;
    }

    api.Version.get()
      .then(res => {
        logger.log('got version res', res);

        let foundVersion = _.find(res, v => {
          return v.Platform === 'Web';
        });

        if (foundVersion) {
          let serverMinVersion = foundVersion.MinVersion;
          let appVersion = pkg.version;

          logger.log(
            `version compare server:${serverMinVersion} web:${appVersion}`
          );
          let needVersionUpgrade = semver.lt(appVersion, serverMinVersion);
          if (needVersionUpgrade) {
            logger.log('DO FORCE VERSION WEB');
            setShowingVersionPrompt(true);
            dispatch(
              showConfirm(
                'New Version Available',
                'There is a new version of this page available.  Press ok to reload the page.',
                res => {
                  if (res) {
                    window.location.reload();
                  }
                  setShowingVersionPrompt(false);
                }
              )
            );
          } else {
            logger.log('NO WEB FORCE VERSION');
          }
        }
      })
      .catch(err => {
        logger.log('error fetching version', err);
      });
  };

  const handleAuthenticatedQuerystring = parsedQs => {
    //tricky case.  This requires authentication and a signing ID.
    //If the user the link is for isn't the currently logged in person (or there isn't a logged in person)
    //we need to bounce them through authentication first, while catching the querystring we save (above).

    //So if you ARE logged in, and the email in  the QS matches, send them right to the dashboard, which will deal with this case.
    //If not, the querystring is saved above, so redirect to login, an upon successful login, and then forwards to dashboard which then deals with this.

    let token = juratLocalStorage.get(c.storage.token);
    let email = juratLocalStorage.get(c.storage.email);

    if (!token || !email || parsedQs[c.querystring.email] !== email) {
      //Require login, clear their token and set their email to the one from the QS that they expect.
      juratLocalStorage.remove(c.storage.token);
      juratLocalStorage.set(c.storage.email, parsedQs[c.querystring.email]);
      dispatch(setPreAuthEmail(parsedQs[c.querystring.email]));
      <Navigate to="/login" />;
      return;
    } else {
      this.props.history.push('/');
    }
  };

  useEffect(() => {
    //-----------------Inrecom Initialization logically for both visitor and loggedin user
    let interComObj = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'ogob6jks',
      custom_launcher_selector: '.intercom-chatbot-toggle'
    };
    if (userMaster) {
      interComObj.name = userMaster.FirstName + ' ' + userMaster.LastName; // Full name
      interComObj.email = userMaster.Email; // Email address
      interComObj.created = moment(userMaster.Created).unix(); // Signup date as a Unix timestamp
      interComObj.user_id = userMaster.GUID; // Full name
      interComObj.user_hash = CryptoJS.HmacSHA256(
        'wUr2JcTufvAcd0Nojzp5BdXTEl43My-OZ4C0lDTl',
        userMaster.GUID
      );
    } else {
    }
    logger.log('Intercom loggin data', interComObj);
    window.Intercom('boot', interComObj);

    //---------------------------------------
  }, [userMaster]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      {networkStatus === c.actions.app.networkStatusOnline && (
        <AuthProvider>
          {isLoadingProfile ? (
            <Flex
              direction="column"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              className="vh-100"
            >
              <Spinner />{' '}
              <div className="mt-2">
                <AppStageView />
              </div>
            </Flex>
          ) : (
            <Layout />
          )}
        </AuthProvider>
      )}

      {networkStatus === c.actions.app.networkStatusOffline && (
        <OfflineNetwork />
      )}
    </Router>
  );
};

export default App;
