import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState } from 'react';
import { Alert, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePersistIdNumbersAction,
  updatePrivacyModeAction
} from 'stores/app';

const AccountSettings = () => {
  const { updatingPrivacyMode, updatingPersistIdNumbers, userMaster } =
    useSelector(state => state.app);

  const dispatch = useDispatch();

  const updatePrivacyMode = privacyMode => {
    let updatedUserMaster = Object.assign({}, userMaster);
    updatedUserMaster.PrivacyEnabled = privacyMode;
    dispatch(updatePrivacyModeAction(userMaster?.GUID, updatedUserMaster));
  };

  const updatePersistIdNumbersMode = persistIdNumbersMode => {
    let updatedUserMaster = Object.assign({}, userMaster);
    updatedUserMaster.PersistIdNumbers = persistIdNumbersMode;
    dispatch(updatePersistIdNumbersAction(userMaster?.GUID, updatedUserMaster));

    //dispatch(updatePersistIdNumbersAction(userMaster?.GUID, persistIdNumbersMode));
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Account Settings" />
      <Card.Body className="bg-light">
        <div className="ps-2">
          <Row>
            <Col className="d-flex" lg={12}>
              <Form.Check
                type="switch"
                id="custom-switch-privacy-mode"
                label="Enable Privacy mode"
                className="form-label-nogutter"
                name="numberVisibility"
                onChange={() => updatePrivacyMode(!userMaster?.PrivacyEnabled)}
                checked={userMaster?.PrivacyEnabled}
              />{' '}
              {updatingPrivacyMode ? (
                <Spinner className="ms-2" size="sm" />
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>

        <div className="ps-2">
          <Row>
            <Col className="d-flex" lg={12}>
              <Form.Check
                type="switch"
                id="custom-switch-privacy-mode"
                label="Persist Id Number (Mobile App)"
                className="form-label-nogutter"
                name="numberVisibility"
                onChange={() =>
                  updatePersistIdNumbersMode(!userMaster?.PersistIdNumbers)
                }
                checked={userMaster?.PersistIdNumbers}
              />{' '}
              {updatingPersistIdNumbers ? (
                <Spinner className="ms-2" size="sm" />
              ) : (
                <></>
              )}
            </Col>
            {!userMaster?.PersistIdNumbers && (
              <Col className="d-flex" lg={12}>
                <Alert variant="info fs--1 p-2 mt-2">
                  When disabled, any ID scan will omit the serial number. This
                  feature is for use in the mobile app. Your setting choice here
                  will be reflected in the mobile app.
                </Alert>
              </Col>
            )}
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AccountSettings;
