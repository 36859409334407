import SettingsPanel from 'components/settings-panel/SettingsPanel';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import is from 'is_js';
import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import MainLayout from './MainLayout';

import SimpleLogin from 'components/authentication/simple/Login';
import WizardAuth from 'components/authentication/wizard/WizardAuth';
import { CloseButton } from 'components/common/Toast';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Wizard from 'components/wizard/Wizard';
import AppContext from 'context/Context';
import { toast, ToastContainer } from 'react-toastify';
import ErrorLayout from './ErrorLayout';

import ForgetPassword from 'components/authentication/simple/ForgetPassword';
import DevicesList from 'components/pages/account/devices/DevicesList';
import NotaryProfile from 'components/pages/account/notary-profile/NotaryProfile';
import UserProfile from 'components/pages/account/user-profile/UserProfile';
import Verification from 'components/pages/account/verification/Verification';
import PaymentHistoryList from 'components/pages/billing/payment-history/PaymentHistoryList';
import PaymentMethodStripe from 'components/pages/billing/payment-method/paymentMethodStripe';
import Subscriptions from 'components/pages/billing/subscription/Subscriptions';
import Dashboard from 'components/pages/dashboard/default';
import AffiliateProgram from 'components/pages/extra/AffiliateProgram';
import JuratBadges from 'components/pages/extra/Badges';
import FeatureRequest from 'components/pages/extra/FeatureRequest';
import Faqs from 'components/pages/knowledge-center/faq/Faqs';
import DocumentTypes from 'components/pages/library/document-types/DocumentTypes';
import FavoriteLocations from 'components/pages/library/favourite-locations/FavoriteLocations';
import SigningPackages from 'components/pages/library/signing-package/SigningPackages';
import Drafts from 'components/pages/notary/drafts/Drafts';
import Journal from 'components/pages/notary/journal/Journal';
import JuratReportingList from 'components/pages/notary/reporting/JuratReportingList';
import Signing from 'components/pages/notary/signing/Signing';
import SigningSuccess from 'components/pages/notary/signing/SigningSuccess';
import { useAuth } from 'hooks/useAuth';
import logger from 'util/logger';
import { ProtectedLayout } from './ProtectedLayout';
import UnProtectedLayout from './UnProtectedLayout';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);



  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  let auth = useAuth();
  logger.log('isAuth', auth.isAuth());

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}
        {/*----------------Juratnic Routes Start-----------*/}

        <Route element={<AuthSimpleLayout />}>
          <Route
            path="login"
            element={
              <UnProtectedLayout>
                <SimpleLogin />
              </UnProtectedLayout>
            }
          />
        </Route>
        <Route element={<WizardAuth />}>
          <Route
            path="signup"
            element={
              <UnProtectedLayout>
                <Wizard validation={true} />
              </UnProtectedLayout>
            }
          />

        </Route>
        <Route element={<AuthSimpleLayout />}>
          <Route
            path="forgot-password"
            element={
              <UnProtectedLayout>
                <ForgetPassword />
              </UnProtectedLayout>
            }
          />
        </Route>

        <Route
          element={
            <ProtectedLayout>
              <MainLayout />
            </ProtectedLayout>
          }
        >
          {/*----------------Juratnic Routes Dashboard Start-----------*/}
          <Route path="/" element={<Dashboard />} />
          <Route path="notary/journal" element={<Journal />} />

          <Route path="notary/drafts" element={<Drafts />} />
          <Route path="notary/signing" element={<Signing />} />
          <Route path="notary/signing/success" element={<SigningSuccess />} />
          <Route path="notary/signing/:urlDraftId" element={<Signing />} />
          <Route path="notary/reporting" element={<JuratReportingList />} />

          <Route path="account/user-profile" element={<UserProfile />} />
          <Route path="account/notary-profile" element={<NotaryProfile />} />
          <Route path="account/verification" element={<Verification />} />
          <Route path="account/devices" element={<DevicesList />} />

          <Route path="library/document-types" element={<DocumentTypes />} />
          <Route
            path="library/favorite-locations"
            element={<FavoriteLocations />}
          />
          <Route
            path="library/signing-packages"
            element={<SigningPackages />}
          />

          <Route path="knowledge-center/faqs" element={<Faqs />} />

          <Route path="extra/feature-request" element={<FeatureRequest />} />
          <Route path="extra/badges" element={<JuratBadges />} />
          <Route
            path="extra/affiliate-program"
            element={<AffiliateProgram />}
          />

          <Route path="billing/subscriptions" element={<Subscriptions />} />
          <Route path="billing/payment-history" element={<PaymentHistoryList />} />
          <Route path="billing/payment-method" element={<PaymentMethodStripe />} />

          {/*----------------Juratnic Routes Dashboard end-------------*/}
        </Route>
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      {auth.isAuth() && (
        <>
          <SettingsToggle />
          <SettingsPanel />
        </>
      )}

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_CENTER}
      />
    </>
  );
};

export default Layout;
