import { createSlice } from '@reduxjs/toolkit';
import deviceHelper from 'helpers/device-helper';
import moment from 'moment';
import { toast } from 'react-toastify';
import apiHelper from 'util/apiHelper';
import c from 'util/const';
import juratLocalStorage from 'util/local-storage';
import api from '../util/api';
import logger from '../util/logger';
import { setDeviceGUID } from './authentication';
import { dashboardCleanup } from './dashboard';
import {
  cleanupSubscription,
  refreshAllSubscriptionData,
  refreshStripeKey
} from './subscription';
import _ from 'lodash';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    startupQS: null,
    userMaster: null,
    userProfile: null,
    savedLocations: null,
    bluetoothMgr: null,
    isExpectedOutsideNavigation: false,
    hasLoadedAnEntryBefore: false,
    subscriptionList: null,
    //At first glance, this should be with new entry.
    //However, this is really app-state related.  This speaks to storage and configuration.
    draftSaveStatus: c.draftStatus.None,
    draftSaveTime: null,
    draftSaveError: null,
    appLoadError: null,
    stage: null,
    updatingPrivacyMode: false,
    updatingPersistIdNumbers: false,
    networkStatus: c.actions.app.networkStatusOnline,
    dataExportMonths: null,
    userSignature: null,
    userJournalNotes: null,
    userOathStatement: null,
    journalList: null
  },
  reducers: {
    setStartupQS: (state, action) => {
      state.startupQS = action.payload;
    },

    setUserMaster: (state, action) => {
      state.userMaster = action.payload;
    },

    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setUserProfilePic: (state, action) => {
      state.userMaster = {
        ...state.userMaster,
        Blob: {
          ...state.userMaster.Blob,
          GUID: action?.payload?.Blob?.GUID
        }
      };
    },

    setStage: (state, action) => {
      state.stage = action.payload;
    },

    setAppLoadError: (state, action) => {
      state.appLoadError = action.payload;
    },

    setSavedLocations: (state, action) => {
      state.savedLocations = action.payload;
    },

    setUpdatePrivacyMode: (state, action) => {
      state.updatingPrivacyMode = action.payload;
    },

    setUpdatePersistIdNumbers: (state, action) => {
      state.updatingPersistIdNumbers = action.payload;
    },

    setDraftSaveStatus: (state, action) => {
      state.draftSaveStatus = action.payload;
    },

    setDraftSaveError: (state, action) => {
      state.draftSaveError = action.payload;
    },

    setSubscriptions: (state, action) => {
      state.subscriptionList = action.payload.subscriptionList;
    },
    cleanupState: (state, action) => {
      state.userMaster = null;
      state.userProfile = null;
      state.startupQS = null;
      state.stage = c.stages.NULL;
      state.dataExportMonths = null;
      state.savedLocations = null;
      state.subscriptionList = null;
      state.appLoadError = null;
    },
    setNetworkStatus: (state, action) => {
      state.networkStatus = action.payload;
    },
    setDataExportMonths: (state, action) => {
      state.dataExportMonths = action.payload;
    },
    setUserSignature: (state, action) => {
      state.userSignature = action.payload;
    },
    setUserJournalNotes: (state, action) => {
      state.userJournalNotes = action.payload;
    },
    setUserOathStatement: (state, action) => {
      state.userOathStatement = action.payload;
    },
    setJournalList: (state, action) => {
      state.journalList = action.payload;
    }
  }
});

let {
  setUserMaster,
  setUserProfile,
  setDraftSaveStatus,
  setDraftSaveError,
  setSubscriptions,
  cleanupState,
  setStartupQS,
  setStage,
  setAppLoadError,
  setSavedLocations,
  setUpdatePrivacyMode,
  setUpdatePersistIdNumbers,
  setNetworkStatus,
  setDataExportMonths,
  setUserSignature,
  setUserJournalNotes,
  setUserOathStatement,
  setJournalList,
  setUserProfilePic
} = appSlice.actions;

let actions = {
  refreshAllAccountInfo: () => {
    return (dispatch, getState) => {
      // return Promise.all([
      //   dispatch(actions.refreshUserMaster()),
      //   dispatch(actions.refreshUserProfile()),
      //   dispatch(refreshAllSubscriptionData()),
      //   dispatch(refreshStripeKey())
      // ])
      return new Promise(function (resolve, reject) {
        dispatch(actions.refreshUserMaster()).then(res => {
          dispatch(actions.refreshUserProfile()).then(res => {
            dispatch(refreshAllSubscriptionData())
              .then(res => {
                dispatch(refreshStripeKey()).then(res => {
                  dispatch(refreshDataExportMonths());
                  resolve(true);
                });
              })
              .catch(err => {
                dispatch(
                  actions.actionSetAppLoadError(
                    "You don't have an active subscription with jurat.inc."
                  )
                );
                reject(err);
              });
          });
        });
      });
    };
  },

  refreshUserMaster: () => {
    return (dispatch, getState) => {
      dispatch(setStage(c.stages.DETAILS));
      return new Promise((resolve, reject) => {
        api.Token.fetchUser()
          .then(res => {
            dispatch(setUserMaster(res.User));
            resolve(res.User);
          })
          .catch(err => {
            logger.warn('error refreshing current user', err);
            dispatch(setUserMaster(null));
            reject(err);
          });
      });
    };
  },

  refreshUserMasterDetails: GUID => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        if (GUID) {
          api.UserMaster.get(GUID)
            .then(res => {
              dispatch(setUserMaster(res));
              resolve(res);
            })
            .catch(err => {
              logger.warn('error refreshing current user', err);
              dispatch(setUserMaster(null));
              reject(err);
            });
        } else reject(false);
      });
    };
  },

  updateUserMaster: (GUID, param) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.UserMaster.update(GUID, param)
          .then(res => {
            dispatch(setUserMaster(res));
            resolve(res);
          })
          .catch(err => {
            logger.warn('error refreshing current user', err);
            reject(err);
          });
      });
    };
  },

  actionSetDraftSaveStatus: payload => {
    return (dispatch, getState) => {
      dispatch(setDraftSaveStatus(payload));
    };
  },

  actionSetAppLoadError: payload => {
    return (dispatch, getState) => {
      dispatch(setAppLoadError(payload));
    };
  },

  actionSetDraftSaveError: payload => {
    return (dispatch, getState) => {
      dispatch(setDraftSaveError(payload));
    };
  },

  refreshSubscription: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.Subscription.status()
          .then(res => {
            dispatch(
              setSubscriptions({
                type: c.actions.app.setSubscriptions,
                subscriptionList: res
              })
            );
            juratLocalStorage.set(c.storage.subscriptionListBackup, res);
            resolve(res);
          })
          .catch(err => {
            logger.warn('error refreshing current subscriptions', err);
            if (apiHelper.isConnectionError(err)) {
              juratLocalStorage
                .get(c.storage.subscriptionListBackup)
                .then(cacheRes => {
                  if (cacheRes) {
                    dispatch({
                      type: c.actions.app.setSubscriptions,
                      subscriptionList: cacheRes
                    });
                    resolve(cacheRes);
                  } else {
                    logger.log('no subscription list backup', cacheRes);
                    reject(err);
                  }
                })
                .catch(fetchErr => {
                  logger.log(
                    'error fetching subscription list backup',
                    fetchErr
                  );
                  //return original error.
                  reject(err);
                });
            } else {
              reject(err);
            }
          });
      });
    };
  },

  refreshUserProfile: () => {
    return (dispatch, getState) => {
      dispatch(setStage(c.stages.PROFILE));
      return new Promise((resolve, reject) => {
        api.Profile.get()
          .then(res => {
            logger.log('refresh user profile resp', res);
            dispatch(setUserProfile(res));
            resolve(res);
          })
          .catch(err => {
            logger.warn('error refreshing current user', err);
            dispatch(setUserProfile(null));
            reject(err);
          });
      });
    };
  },

  refreshSavedLocation: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.SavedLocations.list()
          .then(res => {
            logger.log('got saved locations', res);
            dispatch(setSavedLocations(res));
            juratLocalStorage.set(
              c.storage.savedLocationsBackup,
              _.sortBy(res, d => {
                return -moment(d.Created).unix();
              })
            );
            resolve(res);
          })
          .catch(err => {
            logger.warn('error refreshing saved locations', err);
            if (apiHelper.isConnectionError(err)) {
              juratLocalStorage
                .get(c.storage.savedLocationsBackup)
                .then(cacheRes => {
                  if (cacheRes) {
                    dispatch(setSavedLocations(cacheRes));
                    resolve(cacheRes);
                  } else {
                    logger.log('no saved location backup', cacheRes);
                    reject(err);
                  }
                })
                .catch(fetchErr => {
                  logger.log('error fetching saved location backup', fetchErr);
                  //return original error.
                  reject(err);
                });
            } else {
              reject(err);
            }
          });
      });
    };
  },

  updatePrivacyModeAction: (GUID, param) => {
    //setUpdatePrivacyMode
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        dispatch(setUpdatePrivacyMode(true));
        api.UserMaster.update(GUID, param)
          .then(res => {
            //logger.log("updatePrivacyModeAction res", res);
            dispatch(setUpdatePrivacyMode(false));
            if (res?.GUID) {
              toast.success(res.message, {
                theme: 'colored'
              });
              dispatch(refreshUserMaster());
            } else {
              toast.error(res.message, {
                theme: 'colored'
              });
            }
          })
          .catch(err => {
            dispatch(setUpdatePrivacyMode(false));
            toast.warning('Unable to update privacy mode' + err, {
              theme: 'colored'
            });
          });
      });
    };
  },

  updatePersistIdNumbersAction: (GUID, param) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        dispatch(setUpdatePersistIdNumbers(true));
        api.UserMaster.update(GUID, param)
          .then(res => {
            dispatch(setUpdatePersistIdNumbers(false));
            if (res?.GUID) {
              toast.success(res.message, {
                theme: 'colored'
              });
              dispatch(refreshUserMaster());
            } else {
              toast.error(res.message, {
                theme: 'colored'
              });
            }
          })
          .catch(err => {
            dispatch(setUpdatePersistIdNumbers(false));
            toast.warning('Unable to update texas mode' + err, {
              theme: 'colored'
            });
          });
      });
    };
  },

  consumeQuerystring: () => {
    return (dispatch, getState) => {
      dispatch(setStartupQS(null));
    };
  },

  initStartupQS: qs => {
    return (dispatch, getState) => {
      dispatch(setStartupQS(qs));
    };
  },

  cleanup: () => {
    return (dispatch, getState) => {
      return Promise.all([
        dispatch(cleanupState()),
        dispatch(cleanupSubscription()),
        dispatch(dashboardCleanup())
      ]);
    };
  },

  setStageValue: s => {
    return (dispatch, getState) => {
      dispatch(setStage(s));
    };
  },
  saveContactInformation: contactInfo => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        // dispatch(setUpdatePrivacyMode(true));
        api.UserMaster.changeContactInformation(user.GUID, contactInfo)
          .then(res => {
            dispatch(refreshAllAccountInfo());
            return true;
          })
          .then(() => {})
          .catch(err => {
            if (apiHelper.isConnectionError(err)) {
              dialog.showConnectionErr();
            } else {
              dialog.alert(
                'Unable to change contact information',
                'There was a problem changing your contact information.  Please try again.'
              );
            }
          });
      });
    };
  },

  addDevice: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        logger.log('Adding a device app.js addDevice');
        return deviceHelper
          .addCurrentDevice()
          .then(deviceCheckRes => {
            logger.log('add device res', deviceCheckRes);
            dispatch(setDeviceGUID(_.get(deviceCheckRes, 'GUID')));
            resolve(deviceCheckRes);
          })
          .catch(err => {
            logger.log('error adding device', err);
            dispatch(setDeviceGUID(null));
            reject(null);
          });
      });
    };
  },
  actionSetNetworkStatusOnline: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        dispatch(setNetworkStatus(c.actions.app.networkStatusOnline));
      });
    };
  },
  actionSetNetworkStatusOffline: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        dispatch(setNetworkStatus(c.actions.app.networkStatusOffline));
      });
    };
  },

  refreshDataExportMonths: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.Signing2.monthly()
          .then(res => {
            let updatedDataExportMonths = [];
            let displayKeyCounter = 0;
            _.map(res, function (value, key) {
              let dt = moment(key + '-01');
              updatedDataExportMonths.push({
                //displayKey: ++displayKeyCounter,
                displayKey: key,
                begin: dt.format('YYYY-MM-DD'),
                title: dt.format('MMMM-YYYY'),
                end: dt.endOf('month').format('YYYY-MM-DD'),
                signingCount: value
              });
            });

            dispatch(setDataExportMonths(updatedDataExportMonths));
            resolve(res);
          })
          .catch(err => {
            logger.warn('error refreshing DataExportMonths', err);
            dispatch(setDataExportMonths(null));
            reject(err);
          });
      });
    };
  },

  //=============================PDF data upload===============================================
  savePdfDataToServer: (data, name, additionalData) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.JournalExport.create(data, name, additionalData)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            logger.warn('error unable to save', err);
            reject(err);
          });
      });
    };
  },

  getSavedExport: (guid, includeData) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.JournalExport.get(guid, includeData)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            logger.warn('error unable to load', err);
            reject(err);
          });
      });
    };
  },

  getAllSavedExports: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.JournalExport.getAll()
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            logger.warn('error unable to load', err);
            reject(err);
          });
      });
    };
  },

  deleteSavedExport: guid => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.JournalExport.delete(guid)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            logger.warn('error unable to load', err);
            reject(err);
          });
      });
    };
  },

  //=============================User Signature===============================================
  refreshUserSignature: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.UserSignature.getAll(true)
          .then(res => {
            dispatch(setUserSignature(res));
            resolve(res);
          })
          .catch(err => {
            logger.warn('error refreshing user signature', err);
            dispatch(setUserSignature(null));
            reject(err);
          });
      });
    };
  },
  addUserSignature: data => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.UserSignature.save(data)
          .then(res => {
            dispatch(refreshUserSignature());
            resolve(res);
          })
          .catch(err => {
            logger.warn('Unable to save user signature', err);
            reject(err);
          });
      });
    };
  },

  removeUserSignature: GUID => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.UserSignature.remove(GUID)
          .then(res => {
            dispatch(refreshUserSignature());
            resolve(res);
          })
          .catch(err => {
            logger.warn('Unable to save user signature', err);
            reject(err);
          });
      });
    };
  },

  //==============================User JournalNotes===================================

  refreshUserJournalNotes: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.JournalNotes.getAll(true)
          .then(res => {
            dispatch(setUserJournalNotes(res));
            resolve(res);
          })
          .catch(err => {
            logger.warn('error refreshing user JournalNotes', err);
            dispatch(setUserJournalNotes(null));
            reject(err);
          });
      });
    };
  },

  addUserJournalNotes: Statement => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.JournalNotes.save(Statement)
          .then(res => {
            dispatch(refreshUserJournalNotes());
            resolve(res);
          })
          .catch(err => {
            logger.warn('Unable to save user JournalNotes', err);
            reject(err);
          });
      });
    };
  },

  removeUserJournalNotes: GUID => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.JournalNotes.remove(GUID)
          .then(res => {
            dispatch(refreshUserJournalNotes());
            resolve(res);
          })
          .catch(err => {
            logger.warn('Unable to save user JournalNotes', err);
            reject(err);
          });
      });
    };
  },

  //==============================User Oath Script===================================

  refreshUserOathStatement: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.UserOath.getAll(true)
          .then(res => {
            dispatch(setUserOathStatement(res));
            resolve(res);
          })
          .catch(err => {
            logger.warn('error refreshing user oathstatement', err);
            dispatch(setUserOathStatement(null));
            reject(err);
          });
      });
    };
  },

  addUserOathStatement: Statement => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.UserOath.save(Statement)
          .then(res => {
            dispatch(refreshUserOathStatement());
            resolve(res);
          })
          .catch(err => {
            logger.warn('Unable to save user Oath statement', err);
            reject(err);
          });
      });
    };
  },

  updateUserOathStatement: (GUID, Statement) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.UserOath.update(GUID, Statement)
          .then(res => {
            dispatch(refreshUserOathStatement());
            resolve(res);
          })
          .catch(err => {
            logger.warn('Unable to save user Oath statement', err);
            reject(err);
          });
      });
    };
  },

  removeUserOathStatement: GUID => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.UserOath.remove(GUID)
          .then(res => {
            dispatch(refreshUserOathStatement());
            resolve(res);
          })
          .catch(err => {
            logger.warn('Unable to save user signature', err);
            reject(err);
          });
      });
    };
  },
  setJournalEntryList: entries => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        dispatch(setJournalList(entries));
        resolve(entries);
      });
    };
  },

  listDraftFromServer: incldata => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.SigningDraft.list(incldata)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    };
  },

  createDraftOnServer: payload => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.SigningDraft.create({
          AdditionalData: null,
          Body: payload
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    };
  },

  updateDraftOnServer: (GUID, payload, AdditionalData = null) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.SigningDraft.update(GUID, {
          AdditionalData: AdditionalData,
          Body: payload
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    };
  },

  removeDraftFromServer: guid => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        api.SigningDraft.remove(guid)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    };
  },
  actionUpdateProfilePic: data => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        return dispatch(setUserProfilePic(data));
      });
    };
  }
};

export const {
  actionUpdateProfilePic,
  refreshUserMaster,
  refreshUserMasterDetails,
  updateUserMaster,
  actionSetDraftSaveStatus,
  actionSetDraftSaveError,
  actionSetAppLoadError,
  refreshSubscription,
  refreshUserProfile,
  refreshAllAccountInfo,
  initStartupQS,
  consumeQuerystring,
  cleanup,
  setStageValue,
  refreshSavedLocation,
  updatePrivacyModeAction,
  updatePersistIdNumbersAction,
  addDevice,
  actionSetNetworkStatusOnline,
  actionSetNetworkStatusOffline,
  refreshDataExportMonths,
  refreshUserSignature,
  addUserSignature,
  removeUserSignature,
  refreshUserJournalNotes,
  addUserJournalNotes,
  removeUserJournalNotes,
  refreshUserOathStatement,
  addUserOathStatement,
  updateUserOathStatement,
  removeUserOathStatement,
  setJournalEntryList,
  savePdfDataToServer,
  getAllSavedExports,
  getSavedExport,
  deleteSavedExport,
  listDraftFromServer,
  createDraftOnServer,
  updateDraftOnServer,
  removeDraftFromServer
} = actions;

export default appSlice.reducer;
