import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import juratLocalStorage from 'util/local-storage';
import c from 'util/const';
import DraftEntry from 'components/models/DraftEntry';
import logger from 'util/logger';
import { faLaptop, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import apiHelper from 'util/apiHelper';
import filters from 'util/filters';
import utils from 'util/utils';
import enums from 'util/enums';
import { resolve } from 'superagent/lib/request-base';
import { reject } from 'lodash';

const getNewDraftId = () => {
  return new Promise((resolve, reject) => {
    let newId = c.storage.draftEntryIdPrefix + uuidv4();
    logger.log('Created new draft id', newId);
    return resolve(newId);
    // .then((res) => {
    //   logger.log('Response new draft id', res);
    //   if (!res) {
    //     logger.log('get new draft id', res);
    //     resolve(newId);
    //   }
    //   else {
    //     logger.log('Response else new draft id', res);
    //     resolve(getNewDraftId());
    //   }
    // })
    // .catch((err) => {
    //   logger.log("Catch Error", err);
    //   reject(err);
    // })
  });
};

const getDraftIdListKey = userGUID => {
  return `${c.storage.draftEntryIdList}${userGUID}`;
};

const getDraftIdList = async userGUID => {
  let res = juratLocalStorage.get(getDraftIdListKey(userGUID));
  if (userGUID && res && Array.isArray(res) && res.length > 0) {
    return res;
  } else {
    return [];
  }
};

const refreshLocalDraft = userGUID => {
  let allDraft = draftEntryHelper.getDraftIdList(userGUID);
  return Promise.all([
    _.map(allDraft, draftId => {
      return draftEntryHelper.removeDraft(userGUID, draftId);
    })
  ]).then(res => {
    return true;
  });
};

const removeDraft = async (userGUID, draft_id) => {
  return getDraftIdList(userGUID).then(res => {
    logger.log('Removing', draft_id);
    logger.log(res);
    let update = _.filter(res, i => {
      return i !== draft_id;
    });
    try {
      Promise.all([
        juratLocalStorage.set(getDraftIdListKey(userGUID), update),
        juratLocalStorage.remove(draft_id)
      ])
        .then(res => {
          return true;
        })
        .catch(err => {
          logger.error('Unable to remove draft', err);
        });
    } catch (err) {
      logger.error(err);
    }
    // let update = _.concat([], res);
    // _.remove(update, (thisId) => {
    //   return draft_id === thisId;
    // });

    // logger.log('removing draft', draft_id, update);
    // try {
    //   const res_1 = Promise.all([
    //     juratLocalStorage.set(getDraftIdListKey(userGUID), update),
    //     juratLocalStorage.remove(draft_id)
    //   ]);
    //   resolve(true);
    // } catch (err) {
    //   reject(err);
    // }
  });
};

// const updateDraftStep = (userGUID, draft_id, step) => {
//   return new Promise((resolve, reject) => {
//     Promise.all([
//       getDraft(userGUID, draft_id)
//     ])
//       .then((res) => {
//         let draft = res[0];

//         if (_.find(draftIdListList, (saved_draft_id) => saved_draft_id === draft_id)) {
//           //If it already exists in the list, then we're done.
//           return Promise.resolve(true);
//         }
//         else {
//           draftIdListList.push(draft_id);
//           return juratLocalStorage.set(getDraftIdListKey(userGUID), draftIdListList);
//         }
//       })
//       .then((res) => {
//         resolve(true);
//       })
//       .catch((err) => {
//         reject(err);
//       })
//   })
// }

// const saveUpdateDraft = (userGUID, draft_id, draft_data, entry_data) => {
//   return new Promise((resolve, reject) => {
//     let draftSaveObj = {
//       draft_id,
//       draft_data,
//       entry_data
//     };

//     logger.log('saveUpdateDraft', draftSaveObj);
//     Promise.all([
//       getDraftIdList(userGUID), //looks weird, but we're fetching the list of ids in parallel to the save.  no conflict since we're updating both.
//       juratLocalStorage.set(draft_id, draftSaveObj)
//     ])
//       .then(res => {
//         let draftIdListList = res[0];

//         if (
//           _.find(draftIdListList, saved_draft_id => saved_draft_id === draft_id)
//         ) {
//           //If it already exists in the list, then we're done.
//           return Promise.resolve(true);
//         } else {
//           draftIdListList.push(draft_id);
//           return juratLocalStorage.set(
//             getDraftIdListKey(userGUID),
//             draftIdListList
//           );
//         }
//       })
//       .then(res => {
//         resolve(true);
//       })
//       .catch(err => {
//         reject(err);
//       });
//   });
// };

const saveUpdateDraft = async (userGUID, draft_id, draft_data, entry_data) => {
  try {
    new Promise(async (resolve, reject) => {
      let draftSaveObj = {
        draft_id,
        draft_data,
        entry_data
      };

      getDraftIdList(userGUID).then(async draftIdListList => {
        logger.log('saveUpdateDraft', draftSaveObj);
        juratLocalStorage.set(draft_id, draftSaveObj);
        logger.log('saveUpdateDraft saved');
        if (
          _.find(draftIdListList, saved_draft_id => saved_draft_id === draft_id)
        ) {
          logger.log('saveUpdateDraft exist err 1', draftIdListList, draft_id);
          //If it already exists in the list, then we're done.
          return Promise.resolve(true);
        } else {
          logger.log(
            'saveUpdateDraft updating ids ',
            draftIdListList,
            draft_id
          );
          draftIdListList.push(draft_id);
          try {
            await new Promise((resolve_1, reject_1) => {
              juratLocalStorage.set(
                getDraftIdListKey(userGUID),
                draftIdListList
              );
              logger.log(
                'saveUpdateDraft updated ids ',
                draftIdListList,
                draft_id
              );
              resolve_1(true);
            });
          } catch (err) {
            logger.log('saveUpdateDraft updated err 1', err);
            reject(false);
          }
        }
      });
    })
      .then(res => {
        logger.log('saveUpdateDraft resolved res ', res);
        resolve(true);
      })
      .catch(e => {
        reject(e);
      });
  } catch (err_1) {
    logger.log('saveUpdateDraft updated err 2', err_1);
    reject(err_1);
  }
};

const saveUsersDraftGUIDs = (userGUID, guids) => {
  return juratLocalStorage.set(getDraftIdListKey(userGUID), guids);
};

const getAllDrafts = async userGUID => {
  return new Promise((resolve, reject) => {
    return getDraftIdList(userGUID)
      .then(draftIds => {
        let promises = [];
        _.each(draftIds, draft_id => {
          let d = new Promise(draftResolve => {
            getDraft(userGUID, draft_id)
              .then(draft => {
                draftResolve(draft);
              })
              .catch(err => {
                draftResolve({
                  draft_id,
                  err
                });
              });
            //return errors in results instead of rejecting the Promise.all
          });
          //logger.log('Adding draft in list', d);
          // d.then(dr => {
          //   if (dr !== null) {
          //     promises.push(d);
          //   }
          // });
          if (d !== null) {
            promises.push(d);
          }
        });
        return Promise.all(promises);
      })
      .then(allDraftRes => {
        let filteredDraft = _.filter(allDraftRes, d => d !== null);
        logger.log('all drafts here', filteredDraft);
        resolve(filteredDraft);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getDarftKeyFromId = draft_id => {
  return draft_id ? draft_id.replace(c.storage.draftEntryIdPrefix, '') : '';
};
const getDarftIdFromKey = key => {
  return c.storage.draftEntryIdPrefix + key;
};

const getDraft = async (userGUID, draft_id) => {
  try {
    return await new Promise((resolve, reject) => {
      let draftData = juratLocalStorage.get(draft_id);
      if (draftData && !_.isEmpty(draftData)) {
        logger.log('Get Draft from Storage', draftData);
        let draft_id_1 = _.get(draftData, 'draft_id');
        let draft_data = _.get(draftData, 'draft_data');
        let entry_data = _.get(draftData, 'entry_data');

        let draft = new DraftEntry(draft_id_1);
        draft.setMetadata(draft_data);
        draft.setEntryData(entry_data);
        resolve(draft);
      } else {
        logger.warn('draft was null or empty', userGUID, draft_id, draftData);
        resolve(null);
      }
    });
  } catch (err) {
    reject(err);
  }
};

const getDraftStepName = draftStep => {
  let progress = 1;
  let colorClass = 'primary';
  let statusRowText = 'Step One';
  let code = c.entrySteps.STEP_1;
  let id = 1;
  if (draftStep === c.entrySteps.STEP_1 || draftStep === 1) {
    statusRowText = 'Step One';
    colorClass = 'danger';
    progress = 1;
    code = c.entrySteps.STEP_1;
    id = 1;
  } else if (draftStep === c.entrySteps.STEP_2 || draftStep === 2) {
    statusRowText = 'Step Two';
    colorClass = 'warning';
    progress = 2;
    code = c.entrySteps.STEP_2;
    id = 2;
  } else if (draftStep === c.entrySteps.STEP_3 || draftStep === 3) {
    statusRowText = 'Step Three';
    colorClass = 'info';
    progress = 3;
    code = c.entrySteps.STEP_3;
    id = 3;
  } else if (draftStep === c.entrySteps.STEP_4 || draftStep === 4) {
    statusRowText = 'Step Four';
    colorClass = 'primary';
    progress = 4;
    code = c.entrySteps.STEP_4;
    id = 4;
  } else if (draftStep === c.entrySteps.STEP_5 || draftStep === 5) {
    statusRowText = 'Step Five';
    colorClass = 'success';
    progress = 5;
    code = c.entrySteps.STEP_5;
    id = 5;
  } else if (draftStep === c.entrySteps.STEP_6 || draftStep === 6) {
    statusRowText = 'Step Six';
    colorClass = 'success';
    progress = 6;
    code = c.entrySteps.STEP_6;
    id = 6;
  }
  return {
    label: statusRowText,
    value: _.round((progress * 100) / 6, 0),
    color: colorClass,
    code: code,
    id: id
  };
};

const getSourceIcon = source => {
  let icon = faLaptop;
  if (source === 'Phone') {
    icon = faTabletAlt;
  }
  return (
    <span>
      <FontAwesomeIcon icon={icon} size="sm" />
    </span>
  );
};

const getSigningFromDraft = (
  requestGUID,
  userMasterGuid,
  deviceGUID,
  mStartDate,
  mCompletedDate,
  signers,
  locationInfo,
  notarizationDetails,
  notarizationDocuments,
  documents,
  fingerprints,
  identifications,
  pictures,
  signatures,
  witnesses,
  emailRecipients,
  auditLogArray,
  signingTimestamp,
  localTimezone,
  userProfileGUID
) => {
  let location = apiHelper.buildLocationResult(locationInfo);

  let notaryEntries = [];
  let req = {
    UserMasterGUID: userMasterGuid,
    ...location,
    NotaryEntries: []
  };

  req.SigningTimestamp = signingTimestamp;
  req.LocalTimezone = localTimezone;
  req.UserProfileGUID = userProfileGUID;

  if (deviceGUID) {
    req.DeviceGUID = deviceGUID;
  }

  if (mStartDate) {
    req.Started = mStartDate.format();
  }
  if (mCompletedDate) {
    req.Completed = mCompletedDate.format();
  }

  if (notarizationDetails) {
    //do a little extra checking around notary_fee
    //we did at some point have a customer problem, where an empty string got in here
    //since I'm not totally sure where this came from I think we need to check this forever.
    let notary_fee = _.get(notarizationDetails, 'notary_fee', null);
    // if (notary_fee && notary_fee.length > 0) {
    //   let cleaned = _.trim(notary_fee);
    //   if (cleaned.length > 0 && !_.isNaN(+cleaned)) {
    //     req.NotaryFee = +cleaned;
    //     req.NotaryFeeCurrency = _.get(notarizationDetails, 'currency', 'usd');
    //   }
    // }

    req.NotaryFee = notary_fee || null;
    req.NotaryFeeCurrency = _.get(notarizationDetails, 'currency', 'usd');

    let SigningFees = _.get(notarizationDetails, 'Signing_Fees', []);
    let updatedSigningFees = [];
    if (SigningFees.length > 0) {
      _.map(SigningFees, s => {
        let sObj = Object.assign({}, s);
        delete sObj.id;
        updatedSigningFees.push(sObj);
      });
    }
    req.SigningFees = updatedSigningFees;
    req.AdditionalData = _.get(notarizationDetails, 'notes', '');
  }

  _.each(notarizationDocuments, doc => {
    let entryDetails = {
      NotarizationType: doc.notarization_type,
      DocumentType: doc.document_type
    };

    if (doc.notes) {
      entryDetails.AdditionalData = doc.notes;
    }

    let allBlobs = [];

    //
    // Documents
    //
    let documentList = [];
    if (documents.length > 0) {
      logger.warn('API FEATURE UNSUPPORTED: Sending documents');
    }

    let fingerprintList = [];
    let filteredFingerprints = _.filter(fingerprints, f => {
      return doc.signer_ids.indexOf(f.signer_id) >= 0;
    });
    _.each(filteredFingerprints, f => {
      let signer = _.find(signers, s => s.signer_id === f.signer_id);
      if (signer) {
        fingerprintList.push({
          Prefix: signer.prefix,
          FirstName: signer.first_name,
          MiddleName: signer.middle_name,
          LastName: signer.last_name,
          Suffix: signer.suffix,
          Finger: f.finger,
          // AdditionalData: "" //This is supported, just not currently used.
          Blobs: [
            {
              ContentType: f.imgData.content_type,
              Name: f.finger,
              Description: `${f.finger} for ${signer.first_name} ${signer.last_name}`,
              Data: f.imgData.data
            }
          ]
        });
      }
    });

    let identificationList = [];
    let filteredIdentifications = _.filter(identifications, i => {
      return !i.witness_id && doc.signer_ids.indexOf(i.signer_id) >= 0;
    });
    _.each(filteredIdentifications, i => {
      let signer = _.find(signers, s => s.signer_id === i.signer_id);
      if (signer) {
        let identification = apiHelper.buildIdentificationResult(i);
        identificationList.push(identification);
      }
    });

    //
    // Pictures
    //
    let pictureList = [];
    if (pictures.length > 0) {
      logger.warn('API FEATURE UNSUPPORTED: Sending pictures');
    }

    //
    //Signatures
    //
    let signatureList = [];
    let filteredSignatures = _.filter(signatures, s => {
      return !s.witness_id && doc.signer_ids.indexOf(s.signer_id) >= 0;
    });
    _.each(filteredSignatures, s => {
      let signer = _.find(signers, signer => signer.signer_id === s.signer_id);

      let markWitnesses = _.get(s, 'witnessData');
      let isSignatureByMark = !!(markWitnesses && markWitnesses.length > 0);

      //Checking for imgData is part of a specific workaround to a problem we saw from a customer
      //In which somehow s.imgData.data and s.imgData.content_type evaluate to null
      //Which causes problems in the signing request.  In the cases we've seen, the broken signature
      //seems to be some kind of redundant copy of a real one.
      //regardless, I think we might need this now, because people could have drafts in which this has already happened?
      //I guess?  Always happy for more options I guess.
      //This is an imperfect solution, since if this broken image is not a redundant broken thing, data has actually been lost and this would hide it.
      //we need to do this for witnesses too, since we don't really know where this came from. (see below).
      let imgData = _.get(s, 'imgData.data', '');
      if (signer && imgData.length > 0) {
        if (!isSignatureByMark) {
          signatureList.push({
            IsWitness: false,
            ...{
              Prefix: signer.prefix,
              FirstName: signer.first_name,
              MiddleName: signer.middle_name,
              LastName: signer.last_name,
              Suffix: signer.suffix
            },
            ...location,
            // AdditionalData: "", //Not used but available.
            Blobs: [
              {
                Name: enums.BLOB_TYPES.SIGNATURE,
                Description: `${enums.BLOB_TYPES.SIGNATURE} for ${signer.first_name} ${signer.last_name}`,
                ContentType: s.imgData.content_type,
                Data: s.imgData.data
              }
            ]
          });
        } else {
          //sig by markwitness structure:
          //[
          //  {
          //    signer_id,
          //    address : witnessOneAddress,
          //    identification : witnessOneIdentification,
          //    signature : witnessOneSignature
          //  }
          //]

          //let identification = apiHelper.buildIdentificationResult(witnessIdentification);

          //Then do sig by mark handling.
          signatureList.push({
            IsWitness: false,
            ...{
              Prefix: signer.prefix,
              FirstName: signer.first_name,
              MiddleName: signer.middle_name,
              LastName: signer.last_name,
              Suffix: signer.suffix
            },
            ...location,
            // AdditionalData: "", //Not used but available.
            Blobs: [
              {
                Name: enums.BLOB_TYPES.SIGNATURE,
                Description: `${enums.BLOB_TYPES.SIGNATURE} for ${signer.first_name} ${signer.last_name}`,
                ContentType: s.imgData.content_type,
                Data: s.imgData.data
              }
            ],
            SignByMarkWitnesses: _.map(markWitnesses, markWitness => {
              let mAddress = _.get(markWitness, 'address');
              let mIdentification = _.get(markWitness, 'identification');
              let mSignature = _.get(markWitness, 'signature');

              return {
                ...apiHelper.buildLocationResult(mAddress),
                ...apiHelper.parseNameObjectFromIdentification(mIdentification),
                Blobs: [
                  {
                    Name: enums.BLOB_TYPES.MARK_WITNESS_SIGNATURE,
                    Description: `${enums.BLOB_TYPES.MARK_WITNESS_SIGNATURE} for ${signer.first_name} ${signer.last_name}`,
                    ContentType: mSignature.content_type,
                    Data: mSignature.data
                  }
                ],
                Identifications: [
                  {
                    ...apiHelper.buildIdentificationResult(mIdentification)
                  }
                ]
              };
            })
          });
        }
      }
    });

    //
    //Witnesses
    //
    let witnessList = [];
    let filteredWitnesses = _.filter(witnesses, w => {
      return doc.signer_ids.indexOf(w.signer_id) >= 0;
    });
    _.each(filteredWitnesses, function (w) {
      let signer = _.find(signers, s => s.signer_id === w.signer_id);

      let witness = {
        Prefix: w.witness_name.prefix,
        FirstName: w.witness_name.first_name,
        MiddleName: w.witness_name.middle_name,
        LastName: w.witness_name.last_name,
        Suffix: w.witness_name.suffix,
        ...(signer && { OnBehalfOf: filters.formatNameObj(signer) }),
        Address1: _.get(w, 'location.address_1', ''),
        Address2: _.get(w, 'location.address_2', ''),
        City: _.get(w, 'location.city', ''),
        State: _.get(w, 'location.state', ''),
        PostalCode: _.get(w, 'location.zip', ''),
        Country: 'USA',
        Identifications: [],
        Signatures: []
      };

      let witnessIdentification = _.find(
        identifications,
        i => i.witness_id === w.witness_id
      );
      if (witnessIdentification) {
        let identification = apiHelper.buildIdentificationResult(
          witnessIdentification
        );

        witness.Identifications.push(identification);
      }

      //see above signature blob populate for why it is necessary to check for img size
      let sigImgData = _.get(w, 'signature.data', '');
      if (w.signature && sigImgData.length > 0) {
        witness.Signatures.push({
          IsWitness: true,
          Prefix: w.witness_name.prefix,
          FirstName: w.witness_name.first_name,
          MiddleName: w.witness_name.middle_name,
          LastName: w.witness_name.last_name,
          Suffix: w.witness_name.suffix,
          Address1: _.get(w, 'location.address_1', ''),
          Address2: _.get(w, 'location.address_2', ''),
          City: _.get(w, 'location.city', ''),
          State: _.get(w, 'location.state', ''),
          PostalCode: _.get(w, 'location.zip', ''),
          Country: 'USA',
          Blobs: [
            {
              Name: enums.BLOB_TYPES.SIGNATURE,
              Description: `${enums.BLOB_TYPES.SIGNATURE} for ${w.witness_name.first_name} ${w.witness_name.last_name}`,
              ContentType: w.signature.content_type,
              Data: w.signature.data
            }
          ]
        });
      }

      witnessList.push(witness);
    });

    notaryEntries.push({
      ...entryDetails,
      Blobs: allBlobs,
      Documents: documentList,
      Signatures: signatureList,
      Fingerprints: fingerprintList,
      Identifications: identificationList,
      Pictures: pictureList,
      Witnesses: witnessList
    });
  });

  if (requestGUID) {
    req.RequestGUID = requestGUID;
  }
  req.NotaryEntries = notaryEntries;

  if (emailRecipients && emailRecipients.length > 0) {
    req.Recipients = _.join(emailRecipients, ',');
  }

  if (auditLogArray && auditLogArray.length > 0) {
    let auditLogBlob = {
      Name: 'AuditLog',
      Description: `AuditLog`,
      ContentType: enums.CONTENT_TYPES.APPLICATION_JSON,
      Data: utils.encodeObjectForBlobData(auditLogArray)
    };
    req.Blobs = [auditLogBlob];
  } else {
    req.Blobs = [];
  }
  return req;
};

const draftEntryHelper = {
  getNewDraftId,
  getDraft,
  getAllDrafts,
  getDraftIdList,
  saveUpdateDraft,
  removeDraft,
  getDraftStepName,
  getDarftKeyFromId,
  getDarftIdFromKey,
  getSourceIcon,
  getSigningFromDraft,
  refreshLocalDraft,
  saveUsersDraftGUIDs
};

export default draftEntryHelper;
