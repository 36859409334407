const config = {
  debug: false,
  cryptography: false,
  development: true,
  //=============Production==========================================================
  segmentWriteKey: '5pfhNbBi1ECrXJyGgCKx2Am8AW7KUSAg',
  endpoint: 'https://api.juratinc.com',
  prodEndpoint: 'https://api.juratinc.com',
  reportEndpoint: 'https://reports.juratinc.com',
  // reportEndpoint: 'http://localhost:3001',


  //=============Staging==========================================================
  // segmentWriteKey: 'XBXwuQhWXXZQ63z51UVPOpcPfYc9TPT3', //Fake
  // endpoint: 'http://api.staging.juratinc.com:9001',
  // prodEndpoint: 'http://api.staging.juratinc.com:9001',
  // reportEndpoint: 'https://reports.juratinc.com',

  //=======================================================================
  //-----------------------------App Settings------------------------------
  //=======================================================================
  allowQrCodeInReports: false,
  generateReportOnServer: true
};

export default config;
