import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import AppContext from 'context/Context';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetDraftSaveError } from 'stores/app';
import { actionLogSigningAction } from 'stores/signing';
import logger from 'util/logger';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step6 from './steps/Step6';
import WizardModal from './steps/WizardModal';
import IconAlert from 'components/common/IconAlert';

const SigningLayout = ({
  variant,
  validation,
  progressBar,
  saveSigningToDraft,
  setSavingDraft,
  setIsCompleted,
  isCompleted,
  step,
  setStep
}) => {
  const { isRTL } = useContext(AppContext);
  const {
    notarization_documents,
    signers,
    location,
    signing_timestamp,
    notarization_details
  } = useSelector(state => state.signing);
  const { draftSaveError } = useSelector(state => state.app);
  const [previousAddress, setPreviousAddress] = useState({});
  const [currentState, setCurrentState] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors
  } = useForm();

  const formRef = useRef(null);

  const stepCount = 6;

  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();

  const navItems = [
    {
      icon: 'map-pin',
      label: 'Step 1'
    },
    {
      icon: 'user',
      label: 'Step 2'
    },
    {
      icon: 'dollar-sign',
      label: 'Step 3'
    },
    {
      icon: 'edit',
      label: 'Step 4'
    },
    {
      icon: 'list',
      label: 'Step 5'
    },
    {
      icon: 'thumbs-up',
      label: 'Step 6'
    }
  ];

  const onSubmitData = data => {
    logger.log('Submitted data', data);
    setStep(step + 1);
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handleNavs = targetStep => {
    if (step !== stepCount) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      logger.log('isCompleted', isCompleted);
      if (isCompleted) {
        setStep(6);
        toggle();
      } else {
        setStep(targetStep);
      }
    }
  };

  useEffect(() => {
    if (validateStepsData(step)) {
      saveSigningToDraft();
    }
  }, [step]);

  useEffect(() => {
    setRemoveDraftSaveError();
  }, [currentState]);

  const wasAddressChanged = () => {
    logger.log('wasAddressChanged pre', previousAddress);
    logger.log('wasAddressChanged cur', location);
    return !_.isEqual(previousAddress, location);
  };
  const validateStepsData = step => {
    let errorMessage = null;
    logger.log('Validating Step ', step);
    if (step == 2) {
      if (signing_timestamp == null) {
        errorMessage = 'Notarization Date & Time required';
      }
      // else if (location.address_1 == null || location.address_1 == '') {
      //   errorMessage = 'Address line 1 required';
      // } else if (location.city == null || location.city == '') {
      //   errorMessage = 'City name required';
      // } else if (location.state == null || location.state == '') {
      //   errorMessage = 'State should be selected';
      // } else if (location.zip == null || location.zip == '') {
      //   errorMessage = 'Postal code is required';
      // }
      logger.log('Step errorMessage', errorMessage, signing_timestamp);
      if (errorMessage !== null) {
        setStep(1);
        dispatch(actionSetDraftSaveError({ message: errorMessage }));
        return false;
      } else if (
        location &&
        wasAddressChanged() &&
        draftEntryUiHelper.getDisplayAddress(location).trim() != ''
      ) {
        dispatch(
          actionLogSigningAction(
            `Location changed to ${draftEntryUiHelper.getDisplayAddress(
              location
            )}.`
          )
        );
      }
    }

    logger.log('notarization_details', notarization_details);
    let SigningFees = notarization_details?.Signing_Fees;
    let isEmpty = false;
    _.map(SigningFees, s => {
      if (!s.Name || !s.Amount) {
        isEmpty = true;
        return;
      }
    });

    if (step === 4 && (notarization_documents.length == 0 || isEmpty)) {
      //dispatch(showAlert("Document Required", "Please add at least one document for this notarization."))

      // if (!notarization_details?.notary_fee) {
      //   dispatch(actionSetDraftSaveError({ message: "Notary Fee must filled" }));
      // }
      // else
      if (isEmpty) {
        dispatch(
          actionSetDraftSaveError({
            message: 'Additional Fee must have Fee Type & Amount'
          })
        );
      } else if (notarization_documents.length == 0)
        dispatch(
          actionSetDraftSaveError({
            title: 'Document Required',
            message: 'Please add at least one document for this notarization.'
          })
        );
      setStep(3);
      return false;
    }
    if (step === 5 && signers.length == 0) {
      //dispatch(showAlert("Signers Required", "Please add at least one signer for this notarization."));

      setStep(4);
      dispatch(
        actionSetDraftSaveError({
          title: 'Signers Required',
          message: 'Please add at least one signer for this notarization.'
        })
      );
      return false;
    }
    setCurrentState(step);
    return true;
  };

  const setRemoveDraftSaveError = () => {
    return new Promise((resolve, reject) => {
      dispatch(actionSetDraftSaveError(null));
      resolve(true);
    });
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                    stepCount={stepCount}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                    stepCount={stepCount}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && (
          <ProgressBar
            now={step * (100 / navItems.length)}
            style={{ height: 2 }}
          />
        )}
        <Card.Body className="fw-normal px-md-6 py-4" ref={formRef}>
          {draftSaveError && (
            <Alert
              variant="danger"
              onClose={() => setRemoveDraftSaveError()}
              dismissible
            >
              <Alert.Heading>
                {draftSaveError.title || "You've encountered an error!"}
              </Alert.Heading>
              <p>{draftSaveError.message}</p>
            </Alert>
          )}
          {step === 1 && !isCompleted && (
            <Step1
              register={register}
              errors={errors}
              setValue={setValue}
              saveSigningToDraft={saveSigningToDraft}
              setPreviousAddress={setPreviousAddress}
            />
          )}
          {step === 2 && !isCompleted && (
            <Step2
              register={register}
              errors={errors}
              setValue={setValue}
              saveSigningToDraft={saveSigningToDraft}
              setPreviousAddress={setPreviousAddress}
            />
          )}
          {step === 3 && !isCompleted && (
            <Step3
              register={register}
              errors={errors}
              setValue={setValue}
              saveSigningToDraft={saveSigningToDraft}
            />
          )}
          {step === 4 && !isCompleted && (
            <Step4
              register={register}
              errors={errors}
              setValue={setValue}
              setStep={setStep}
              saveSigningToDraft={saveSigningToDraft}
            />
          )}
          {step === 5 && !isCompleted && (
            <Step5
              register={register}
              errors={errors}
              setValue={setValue}
              setStep={setStep}
              saveSigningToDraft={saveSigningToDraft}
            />
          )}
          {(step === stepCount || isCompleted) && (
            <Step6
              register={register}
              reset={reset}
              setValue={setValue}
              setStep={setStep}
              saveSigningToDraft={saveSigningToDraft}
              setSavingDraft={setSavingDraft}
              setIsCompleted={setIsCompleted}
              isCompleted={isCompleted}
            />
          )}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === stepCount,
            ' d-flex': step < stepCount
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Next
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label, stepCount }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < stepCount ? step > index : step > stepCount - 1,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

SigningLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default SigningLayout;
